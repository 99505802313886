import React from "react"
import "_data/normalize.css"
import "@fontsource/montserrat/800.css"
import "@fontsource/montserrat/700.css"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat/500.css"
import Header from "_components/nav/header/_header"
import { Paragraph, Screen, Content, HeaderContainer } from "_data/global_styles"
import { Link } from "gatsby"
import PageHeadline from "_components/misc/pageHeadline"


const NotFoundPage = () => {
  return (
    <>
      <HeaderContainer>
        <Header />
      </HeaderContainer>
      <Screen>
        <Content>
          <PageHeadline copy="Four Ohhhhhh Four! Page Not Found." />
          <div style={{ marginLeft: "1vw", color: "#303c6c" }}>
            <Paragraph>
              We couldn't find the page you were looking for. How about we get
              you back in business?{"  "}
              <Link
                style={{
                  color: "#123a82",
                  fontWeight: "800",
                  cursor: "pointer",
                  textDecoration: "none",
                }}
                to="/"
              >
                Click here
              </Link>{" "}
              for the homepage.
            </Paragraph>
          </div>
        </Content>
      </Screen>
    </>
  )
}



export default NotFoundPage
